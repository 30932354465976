@import "./style_keys/colors.scss";
@import "./fonts.scss";

html,
body {
  height: 100%;
  background-color: $fond-jaune;
  font-family: OpenSans;
}
.radio-testtype {
  display: flex;
  width: 300px;
  justify-content: space-between;
}
.row {
  display: flex;
  height: 100vh;
}

.row-login-page {
  background-color: $bleu-magentine-2;
  height: 100vh;
}

.header-login {
  padding: 30px;
}

.zone {
  flex: 50%;
  max-height: 100vh;
}

.scrollable-zone {
  overflow-y: scroll;
  scroll-behavior: smooth;
  // overflow-x: hidden;
  padding: 20px 5px;
  margin-bottom: 10px;
}

.filters {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
  float: left;
  background-color: transparent;
}

.filters-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: transparent;
  margin-bottom: 50px;
}

.filterControlContainer {
  margin: 10px 50px 0 10px;
  width: 40%;
  height: auto;
  background-color: transparent;
  z-index: 99999999999999999999999;
}

.filterControl {
  float: left;
  margin: 10px;
  width: 40%;
  height: auto;
  background-color: transparent;
  z-index: 99999999999999999999999;
}

.filterControlButtons {
  float: left;
  margin: 10px;
  width: 80%;
  height: auto;
  background-color: transparent;
  z-index: 99999999999999999999999;
}

.selectionbox {
  width: 250px;
}

.filterbutton {
  background-color: #aaa; /* Green */
  border: none;
  float: right;
  margin-top: 10px;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
}

.tableBox {
  width: 100%;
  height: auto;
  overflow: auto;
  background-color: transparent;
  z-index: -1000000;
}

.mySelect__value-container {
  height: 35px;
}

.left-card {
  border-radius: 0 10px 10px 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  width: 250px;
  height: auto;
  background-color: $bleu-magentine-2;
  padding: 20px;
  box-shadow: 5px -5px 10px 0 rgba(0, 0, 0, 0.15);
  color: $blanc;
  box-sizing: border-box;
}

.step-card {
  width: 100%;
  height: 100%;
}

.step-title {
  box-sizing: border-box;
  width: 80%;
  height: max-content;
  margin: 0 0 60px;
  font-family: OpenSans;
  font-size: 37px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: $blanc;
}

.input-large {
  width: 329px;
  height: 30px;
  margin-bottom: 20px;
  padding: 5px 5px 5px 20px;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border: 0;
  background-color: $blanc;
}

.btnMain {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 34px;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  background-color: $bleu-magentine-2;
  color: $blanc;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border: 0;
}

.btnSecondary {
  width: 150px;
  height: 50px;
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $bleu-magentine-2;
  border-radius: 10px;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  background-color: $blanc;
  border: 0;
  cursor: pointer;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-position: center right;
  background-repeat: no-repeat;
  border: 1px solid #aaa;
  border-radius: 2px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  box-sizing: content-box;
  white-space: nowrap;
}

.left-zone {
  flex: 0.5;
  margin-right: 60px;
}

.home-logo-container {
  width: 20%;
  height: 60px;
}

.menu-logo-container {
  width: 100%;
}

.menu-logo {
  box-sizing: border-box;
  width: 80%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.user-logged {
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.title {
  font-family: OpenSans;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: $textes-courants;
}

.table-title {
  font-family: OpenSans;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: $bleu-magentine-2;
}

.btnText {
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
  cursor: pointer;
}

table {
  border-spacing: 20px;
  border: 1px solid gray;
}

.table-recap {
  border-collapse: collapse;
  table-layout: fixed;
  font-family: OpenSans;
  width: 40%;
  margin-bottom: 20px;
  background-color: $blanc;
  border: 0;
}

.recap-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.see-more-link {
  color: $bleu-magentine-2;
  cursor: pointer;
}

.see-more-link-red {
  color: $rouge;
  cursor: pointer;
}

.subtitle-white {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: center;
  color: $blanc;
}

.link-underline {
  text-align: start;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $blanc;
  cursor: pointer;
}

.login-link-container {
  width: 100%;
  text-align: start;
  margin-bottom: 20px;
}

.login-image-name-container .login-image {
  width: 15%;
  display: block;
  margin: auto;
}

.login-title {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: center;
  color: $blanc;
}

.login-field-container {
  width: min-content;
  margin: auto;
  text-align: center;
}

.small-margin {
  margin-bottom: 5px;
}

.powered-text {
  margin-top: 3%;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: $blanc;
}

.header {
  padding-right: 30px;
  text-align: right;
}

.home-title {
  margin-bottom: 40px;
  font-size: 56px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: $textes-courants;
}

.home-title-action {
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $bleu-magentine-2;
}

.home-container {
  width: 70%;
  margin: auto;
}

.button-action {
  display: table;
  text-align: center;
  margin: 0 20px 20px 20px;
  width: 180px;
  height: 140px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
  background-color: $blanc;
}

.button-action:hover {
  border: solid 2px $bleu-magentine-2;
}

.button-action .button-action-content {
  width: max-content;
  text-align: center;
  margin: auto;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.button-action-content .button-action-icon {
  //width: 70px;
  height: 75px;
  margin: auto;
}

.button-action-text {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $textes-courants;
}

.actions-container {
  width: max-content;
  margin: auto;
  width: 110%;
}

.buttons-action-container {
  display: flex;
  flex-wrap: wrap;
}

.logout-button {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $rouge;
}

.search-field {
  margin-bottom: 10px;
}

.password-container {
  position: relative;
  display: flex;
}

.password-icon {
  position: absolute;
  right: 10px;
  top: 20%;
  cursor: pointer;
  color: #585858;
}

@media (max-width: 1000px) {
  .left-zone {
    flex: 0;
    margin-right: 0;
  }

  .scrollable-zone {
    padding: 20px 30px;
  }

  .header {
    display: flex;
    flex-direction: row;
    padding-right: 0px;
    justify-content: space-between;
    align-items: center;
    text-align: unset;
  }
}
