@import './style_keys/colors.scss';
@import './fonts.scss';

.menu-container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 282px;
    height: calc(100vh - 62px);
    padding: 16px 39px 46px 20px;
    box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.15);
    background-color: $bleu-nav-deployee;
    z-index: 10;
}

.menu-scrollable-zone {
    position: relative;
    height: 90%;
    width: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch; 
    margin-bottom: 20px;
}

.menu-content {
    box-sizing: border-box;
    width: 90%;
    //height: 100%;
    margin-top: 0;
    margin-bottom: 30%;
    margin-left: auto;
    margin-right: auto;
    font-family: OpenSans;
    font-size: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $blanc;
}

.menu-close {
    width: 100%;
    text-align: end;
    margin-bottom: 44px;
}

.menu-close-btn::before {
    content: "\2715";
    color: $blanc;
    cursor: pointer;
}

.menu-close-btn {
    font: normal 40px Arial, sans-serif;
    display: inline-block;
}

.menu-close-btn:hover {
    color: #92a9ff;
}

.menu-icon {
    width: 24px;
    height: 25px;
    margin-right: 20px;
}
.menu-icon-large {
    width: 30px;
    height: 25px;
    margin-right: 15px;
}

.menu-separator {
    box-sizing: border-box;
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    opacity: 0.5;
    border: solid 1px $blanc;
}

.menu-other-text {
    width: 80%;
    margin-top: 5px;
}

.menu-home-text {
    margin-top: 5px;
    font-weight: bold;
}

.menu-icon-zone {
    flex: 0;
}
.menu-text-zone {
    flex: 1;
}

.menu-row-input {
    cursor: pointer;
    display: flex;
    flex-flow: wrap;
    margin-bottom: 20px;
    font-size: OpenSans;
    color: $blanc;
    text-decoration: none;
    width: 100%;
    height: 100%;
    align-items: center;
}

.menu-row-input:hover {
    color: #92a9ff;
}

.menu-footer {
    width: 75%;
    margin: auto;
}

.menu-footer-text {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 35px;
    font-family: OpenSans;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $blanc;
}
.menu-footer-text:hover {
    color: #92a9ff;
}

.copied-box {
    width: 90%;
    height: max-content;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    background-color: $blanc;
    font-family: OpenSans;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    vertical-align: middle;
    color: $textes-courants;
    position: absolute;
    z-index: 10;
}

.hamburger {
    cursor: pointer;
}

.close-menu-btn {
    margin-bottom: 10px;
    cursor: pointer;
}

@media (max-width: 1024px) {

    .menu-container {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 282px;
        height: 100%;
        padding: 16px 39px 46px 20px;
        box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.15);
        background-color: $bleu-nav-deployee;
        z-index: 10;
    }

    .menu-content {
        box-sizing: border-box;
        width: 90%;
        //height: 100%;
        margin-top: 0;
        margin-bottom: 12%;
        margin-left: auto;
        margin-right: auto;
        font-family: OpenSans;
        font-size: 13px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $blanc;
    }

    .menu-scrollable-zone {
        position: relative;
        height: 85%;
        overflow: scroll;
        -webkit-overflow-scrolling: touch; 
        margin-bottom: 20px;
    }

    .menu-close {
        width: 100%;
        text-align: start;
        margin-bottom: 20px;
    }

    .menu-close-btn {
        font: normal 40px Arial, sans-serif;
        display: inline-block;
    }

    .menu-row-input {
        cursor: pointer;
        display: flex;
        flex-flow: wrap;
        margin-bottom: 5px;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    .menu-icon {
        width: 23px;
        height: 23px;
        margin-right: 13px;
    }

    .menu-icon-large {
        width: 25px;
        height: 20px;
        margin-right: 10px;
    }

    .copied-box {
        width: 90%;
        height: max-content;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
        background-color: $blanc;
        font-family: OpenSans;
        font-size: 11px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        vertical-align: middle;
        color: $textes-courants;
        position: absolute;
        z-index: 10;
    }
}

@media (max-width: 1000px) {
    .left-card {
        position: absolute;
        z-index: 10;
    }
}

@media (max-width: 400px) {
    .menu-container {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 16px 39px 46px 20px;
        box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.15);
        background-color: $bleu-nav-deployee;
        z-index: 10;
    }

    .menu-content {
        box-sizing: border-box;
        width: 90%;
        //height: 100%;
        margin-top: 0;
        margin-bottom: 12%;
        margin-left: auto;
        margin-right: auto;
        font-family: OpenSans;
        font-size: 13px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $blanc;
    }

    .menu-scrollable-zone {
        position: relative;
        height: 80%;
        overflow: scroll;
        -webkit-overflow-scrolling: touch; 
        margin-bottom: 20px;
    }

    .menu-close {
        width: 100%;
        text-align: start;
        margin-bottom: 20px;
    }

    .menu-close-btn {
        font: normal 35px Arial, sans-serif;
        display: inline-block;
    }

    .menu-row-input {
        cursor: pointer;
        display: flex;
        flex-flow: wrap;
        margin-bottom: 5px;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    .menu-icon {
        width: 23px;
        height: 22px;
        margin-right: 13px;
    }

    .menu-icon-large {
        width: 25px;
        height: 20px;
        margin-right: 10px;
    }

    .copied-box {
        width: 90%;
        height: max-content;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
        background-color: $blanc;
        font-family: OpenSans;
        font-size: 11px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        vertical-align: middle;
        color: $textes-courants;
        position: absolute;
        z-index: 10;
    }
}