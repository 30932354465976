@font-face {

    font-family: Nunito;
    src: local(Nunito-Light), url(../assets/fonts/Nunito/Nunito-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}
  
  @font-face {
    font-family: OpenSans;
    src: local(OpenSans-Light), url(../assets/fonts/Open_Sans/OpenSans-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
  }
  
  @font-face {
    font-family: OpenSans;
    src: local(OpenSans-Bold), url(../assets/fonts/Open_Sans/OpenSans-Bold.ttf) format("truetype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
  }